/* GENERATED FILE */
import React, { forwardRef, useContext } from "react";
import { IconProps, IconContext } from "../lib";

const renderPathFor = (
  weight: string,
  color: string
): React.ReactNode | null => {
  switch (weight) {
    case "bold":
      return (
        <>
          <path
            d="M224,96V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V96l96-64Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="24"
          />
          <line
            x1="110.54541"
            y1="152.00008"
            x2="34.4668"
            y2="205.73921"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="24"
          />
          <line
            x1="221.53418"
            y1="205.73921"
            x2="145.45424"
            y2="151.99959"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="24"
          />
          <polyline
            points="224 96 145.455 152 110.545 152 32 96"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="24"
          />
        </>
      );
    case "duotone":
      return (
        <>
          <polygon
            points="224 96 145.455 152 110.545 152 32 96 128 32 224 96"
            opacity="0.2"
          />
          <path
            d="M32,96V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V96L128,32Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <line
            x1="110.54541"
            y1="152.00008"
            x2="34.4668"
            y2="205.73921"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <line
            x1="221.53418"
            y1="205.73921"
            x2="145.45424"
            y2="151.99959"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <polyline
            points="224 96 145.455 152 110.545 152 32 96"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
        </>
      );
    case "fill":
      return (
        <>
          <path d="M231.99512,95.92334a7.926,7.926,0,0,0-.22608-1.8147c-.0166-.06909-.03759-.13672-.05615-.20532q-.09154-.33618-.2124-.666c-.03028-.08129-.061-.16186-.09326-.24218-.0835-.20459-.17725-.40625-.27832-.606-.03955-.07764-.07618-.15625-.11768-.23267-.11914-.21679-.25146-.429-.39307-.63745-.03125-.04614-.05761-.09521-.08984-.14062l-.01465-.023a8.19273,8.19273,0,0,0-.55469-.6897c-.04345-.04834-.0913-.09228-.13574-.13965-.16845-.17944-.34228-.35083-.52344-.51172-.061-.05371-.124-.10473-.18652-.15673q-.27686-.23071-.56933-.43311c-.03711-.02563-.06934-.05591-.10645-.08081l-96-64a8.00131,8.00131,0,0,0-8.875,0l-96,64c-.03613.02393-.06689.05322-.102.07764q-.29736.20508-.57666.43847c-.061.05078-.12256.10035-.18164.15284-.18311.16235-.35889.3352-.52832.5166-.04346.0459-.08985.08886-.13233.13574a8.114,8.114,0,0,0-.55517.69043l-.01465.023c-.03223.04541-.05859.09448-.08984.14062-.14161.2085-.27393.42066-.39307.63745-.0415.07642-.07813.155-.11768.23267-.10107.19971-.19482.40137-.27832.606-.03222.08032-.063.16089-.09326.24218q-.12157.33-.2124.666c-.01856.0686-.03955.13623-.05615.20532a7.926,7.926,0,0,0-.22608,1.8147C24.00439,95.94922,24,95.97412,24,96V200a16.01833,16.01833,0,0,0,16,16H216a16.01833,16.01833,0,0,0,16-16V96C232,95.97412,231.99561,95.94922,231.99512,95.92334ZM40,111.52881l56.72266,40.44092L40,192.03662ZM113.08594,160h29.82812L199.543,200H56.45752Zm46.1914-8.03027L216,111.52881v80.50732Z" />
        </>
      );
    case "light":
      return (
        <>
          <path
            d="M224,96V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V96l96-64Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="12"
          />
          <line
            x1="110.54541"
            y1="152.00008"
            x2="34.4668"
            y2="205.73921"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="12"
          />
          <line
            x1="221.53418"
            y1="205.73921"
            x2="145.45424"
            y2="151.99959"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="12"
          />
          <polyline
            points="224 96 145.455 152 110.545 152 32 96"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="12"
          />
        </>
      );
    case "thin":
      return (
        <>
          <path
            d="M224,96V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V96l96-64Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="8"
          />
          <line
            x1="110.54541"
            y1="152.00008"
            x2="34.4668"
            y2="205.73921"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="8"
          />
          <line
            x1="221.53418"
            y1="205.73921"
            x2="145.45424"
            y2="151.99959"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="8"
          />
          <polyline
            points="224 96 145.455 152 110.545 152 32 96"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="8"
          />
        </>
      );
    case "regular":
      return (
        <>
          <path
            d="M32,96V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V96L128,32Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <line
            x1="110.54541"
            y1="152.00008"
            x2="34.4668"
            y2="205.73921"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <line
            x1="221.53418"
            y1="205.73921"
            x2="145.45424"
            y2="151.99959"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <polyline
            points="224 96 145.455 152 110.545 152 32 96"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
        </>
      );
    default:
      console.error(
        'Unsupported icon weight. Choose from "thin", "light", "regular", "bold", "fill", or "duotone".'
      );
      return null;
  }
};

const EnvelopeOpen = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, size, weight, mirrored, children, ...restProps } = props;
  const {
    color: contextColor,
    size: contextSize,
    weight: contextWeight,
    mirrored: contextMirrored,
    ...restContext
  } = useContext(IconContext);

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? contextSize}
      height={size ?? contextSize}
      fill={color ?? contextColor}
      viewBox="0 0 256 256"
      transform={mirrored || contextMirrored ? "scale(-1, 1)" : undefined}
      {...restContext}
      {...restProps}
    >
      {children}
      <rect width="256" height="256" fill="none" />
      {renderPathFor(weight ?? contextWeight, color ?? contextColor)}
    </svg>
  );
});

EnvelopeOpen.displayName = "EnvelopeOpen";

export default EnvelopeOpen;
